.k-animation-container {
  z-index: 1201 !important; // set the Kendo UI Popup on top of other elements
}

// TODO: check if this can avoided
#profile-avatar-actions {
  z-index: 1000002 !important;
}

#side-drawer-layout {
  > .k-drawer-container {
    > .k-drawer-content {
      width: 100%;
      overflow: unset;
    }
  }
}

#side-drawer-small-layout {
  > .k-drawer-container {
    > .k-drawer-content {
      height: 100%;
    }
  }
}

#room-filter-field-listbox-id {
  > li.k-selected {
    background-color: var(--primary-25);
  }
}

#room-filter-field {
  > span.k-input-inner {
    padding: 0;
  }
}
